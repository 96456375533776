import './styles.css';

import PartySocket from 'partysocket';
import DOMPurify from 'dompurify';

declare const PARTYKIT_HOST: string;

// Let's append all the messages we get into this DOM element
const output = document.getElementById('chat') as HTMLDivElement;
const roomInfo = document.getElementById('room') as HTMLDivElement;

function clean(input: string) {
    return DOMPurify.sanitize(input, {
        ALLOWED_TAGS: ['strong'],
    });
}

// Helper function to add a new line to the DOM
function add(data: { type: string; message: string; connections?: number }) {
    const { type, message, connections } = data;

    const div = document.createElement('div');
    div.className = type;
    div.innerHTML = `<p>${clean(message)}</p>`;
    output.appendChild(div);

    // duplicate this emoji for every connection
    const phantoms = '👻 '.repeat(connections ?? 1);

    if (connections) {
        roomInfo.innerHTML = `Welcome to <span class="room">#${room}</span>!<br/>There are currently ${phantoms} haunting this mansion.`;
    }
}

// get url fragmant
const url = new URL(window.location.href);
const room = clean(url.searchParams.get('room') ?? 'carnage');

// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.
const conn = new PartySocket({
    host: PARTYKIT_HOST,
    room: room,
});

// You can even start sending messages before the connection is open!
conn.addEventListener('message', (event) => {
    add(JSON.parse(event.data));
});

// Send a message
document.getElementById('input')?.addEventListener('keypress', (event) => {
    if (event.key === 'Enter') {
        const input = document.getElementById('input') as HTMLInputElement;
        add({
            type: 'message',
            message: `🗣️: ${clean(input.value)}`,
        });
        conn.send(clean(input.value));
        input.value = '';
    }
});
